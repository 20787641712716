import { http } from '@/api/Connect';
import { BASE_WEB_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosPromise, AxiosResponse } from 'axios';

import {
  IStreamingScheduleListParams,
  IStreamingScheduleListResponse,
  TScheduleStreamResponse
} from '@/api/schema';

const API_SCHEDULE = (): string => `${BASE_WEB_OPERATOR()}/schedule`;

export async function getStreamingScheduleList(
  params: IStreamingScheduleListParams
): Promise<IStreamingScheduleListResponse> {
  return http
    .get(API_SCHEDULE(), {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<IStreamingScheduleListResponse>) => data);
}

export async function createScheduleStream(
  payload: FormData
): AxiosPromise<TScheduleStreamResponse> {
  return http.post(API_SCHEDULE(), payload, {
    headers: {
      'Content-Type': undefined
    }
  });
}

export async function updateScheduleStream(
  scheduleId: number | string,
  payload: FormData
): Promise<TScheduleStreamResponse> {
  return http.put(`${API_SCHEDULE()}/${scheduleId}`, payload, {
    headers: {
      'Content-Type': undefined
    }
  });
}

export async function deleteScheduleStream(scheduleId: string): Promise<void> {
  return http.delete(`${API_SCHEDULE()}/${scheduleId}`);
}
