import { render, staticRenderFns } from "./CopyStakeStreamingScheduleTable.vue?vue&type=template&id=654a8064&scoped=true&lang=pug"
import script from "./CopyStakeStreamingScheduleTable.vue?vue&type=script&lang=ts"
export * from "./CopyStakeStreamingScheduleTable.vue?vue&type=script&lang=ts"
import style0 from "./CopyStakeStreamingScheduleTable.vue?vue&type=style&index=0&id=654a8064&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "654a8064",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VIcon})
