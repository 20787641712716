


















































































import { mapGetters } from 'vuex';

const ConfirmationDialog = (): Promise<any> =>
  import('@/components/ConfirmationDialog.vue');
const VStatus = (): Promise<any> => import('@/components/VStatus.vue');
const CopyStakeStreamingScheduleDialog = (): Promise<any> =>
  import(
    '@/views/CopyStake/CopyStakeStreamingSchedule/CopyStakeStreamingScheduleDialog.vue'
  );
const ImagePreviewDialog = (): Promise<any> =>
  import('@/components/ImagePreviewDialog.vue');

import {
  getStreamingScheduleList,
  deleteScheduleStream
} from '@/api/CopyStake/Schedule';
import { EScheduleStreamStatus, TScheduleStream } from '@/api/schema';
import { addThumbnailPrefix } from '@/helpers/copyStakeHelpers';

import { errorToastMessage } from '@/helpers/errorToastMessage';

const statusMap = {
  [EScheduleStreamStatus.PLANNED]: {
    label: 'Planned',
    type: 'additional-progress'
  },
  [EScheduleStreamStatus.FINISHED]: {
    label: 'Finished',
    type: 'additional-finished'
  }
};

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: ['dateOfStream'],
  sortDesc: [false]
};

export default {
  name: 'CopyStakeStreamingScheduleTable',
  components: {
    CopyStakeStreamingScheduleDialog,
    ConfirmationDialog,
    VStatus,
    ImagePreviewDialog
  },
  data(): any {
    return {
      statusMap,
      isLoading: false,
      list: [] as TScheduleStream[],
      total: null,
      options: { ...defaultOptions },
      dialogs: {
        imagePreview: false,
        edit: false,
        delete: false,
        loading: false,
        data: undefined
      }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    canEdit(): boolean {
      return this.$role.can.update('copy_stake streamers');
    },
    headers(): any {
      return [
        { text: 'Date Added (UTC)', value: 'createdAt' },
        { text: 'Nickname', value: 'nickName' },
        { text: 'Start Date (UTC)', value: 'dateOfStream' },
        { text: 'Status', value: 'status', sortable: false },
        {
          text: 'Thumbnails 3x4\xa0/\xa016x9',
          value: 'images',
          sortable: false
        },
        ...[
          this.canEdit
            ? {
                text: 'Action',
                value: 'actions',
                width: 140,
                sortable: false,
                align: 'center'
              }
            : null
        ]
      ].filter(Boolean);
    }
  },
  watch: {
    operatorId: {
      handler: 'fetchStreamingScheduleData'
    }
  },
  methods: {
    addThumbnailPrefix,
    async fetchStreamingScheduleData(): Promise<void> {
      try {
        if (!this.operatorId) return;
        this.isLoading = true;

        const { content, totalElements } = await getStreamingScheduleList({
          size: this.options.itemsPerPage,
          page: this.options.page - 1,
          sort: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? 'DESC' : 'ASC',
          includeOldRecords: true
        });

        this.list = content;
        this.total = totalElements;
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    handlerOnEditScheduleStream(scheduleStream?: TScheduleStream): void {
      this.dialogs.edit = true;

      if (scheduleStream) {
        this.dialogs.data = scheduleStream;
      }
    },
    showDeleteDialog(scheduleStream: TScheduleStream): void {
      this.dialogs.delete = true;
      this.dialogs.data = {
        header: 'Delete Stream',
        disclaimer: `
          <p><strong>
            Are you sure to delete stream?
          </strong></p>
          Info about this stream will be hidden on CopyStake page.`,
        okText: 'Delete',
        scheduleStream
      };
    },
    async handlerOnDeleteScheduleStream(): Promise<void> {
      try {
        this.dialogs.loading = true;

        await deleteScheduleStream(this.dialogs.data.scheduleStream.id);
        this.fetchStreamingScheduleData();
        this.$toast.success('Stream schedule was deleted successfully');

        this.closeDialog('delete');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.dialogs.loading = false;
      }
    },
    closeDialog(type: string): void {
      this.dialogs[type] = false;
      this.$nextTick(() => {
        this.dialogs.data = {};
      });
    },
    handlerOpenGameImagePreviewDialog(imageUrl?: string): void {
      if (!imageUrl) {
        return;
      }

      this.dialogs.imagePreview = true;
      this.dialogs.data = { imageUrl };
    }
  }
};
