











import CopyStakeStreamingScheduleTable from '@/views/CopyStake/CopyStakeStreamingSchedule/CopyStakeStreamingScheduleTable.vue';
import FeatureFlagRedirectMixin from '@/mixins/copy-stake/FeatureFlagRedirectMixin';

export default {
  name: 'CopyStakeStreamingScheduleView',
  mixins: [FeatureFlagRedirectMixin],
  components: {
    CopyStakeStreamingScheduleTable
  }
};
